import {
  createContext,
  createElement,
  FC,
  useRef,
  useContext,
  useCallback
} from 'react';
import { NotOkFetchResponse } from 'packages/http-client/fetcher';

/**
 * @checkReturnValue Must return the parent form transformed value
 */
type HttpMutationFormParentTransformer = () => undefined | object;

/**
 * A transformer for forms that is given the response object and
 * the response body. It is responsible for transforming the response
 * data into a data object that is used for FinalForm to render errors.
 *
 * - Return `undefined` in the case of form submit success
 * - Return an `object` with keys being form fields and errors being the corresponding error
 * - Call `parentTransformer` to delegate to your parent's transform method for an error that you do not handle
 */
export type HttpMutationFormResponseTransformer = (
  response: Response,
  data: any,
  parentTransformer: HttpMutationFormParentTransformer
) => undefined | object;

export type HttpMutationFormResponseTransformerContextValue = (
  response: Response,
  data: any
) => undefined | object;

export const HttpMutationFormResponseTransformContext = createContext<
  HttpMutationFormResponseTransformerContextValue
>(NotOkFetchResponse.assert as HttpMutationFormResponseTransformerContextValue);

export const HttpMutationFormResponseTransform: FC<{
  transform: HttpMutationFormResponseTransformer;
}> = ({ children, transform }) => {
  const transformRef = useRef(transform);
  transformRef.current = transform;

  const parentTransformer = useContext(
    HttpMutationFormResponseTransformContext
  );
  const parentTransformerRef = useRef(parentTransformer);
  parentTransformerRef.current = parentTransformer;

  const transformContextValue = useCallback<
    HttpMutationFormResponseTransformerContextValue
  >(
    (resp, data) =>
      transformRef.current(
        resp,
        data,
        parentTransformerRef.current.bind(null, resp, data)
      ),
    [transformRef, parentTransformerRef]
  );

  return (
    <HttpMutationFormResponseTransformContext.Provider
      value={transformContextValue}
    >
      {children}
    </HttpMutationFormResponseTransformContext.Provider>
  );
};
