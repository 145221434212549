/* eslint-disable react-hooks/exhaustive-deps */
import { RequestConfig } from 'packages/http-client/fetcher';
import { useHttpMutationWithErrorsWithLoadingWithLazyRequest } from 'packages/http-client/react';
import { Config as FinalFormConfig, FORM_ERROR } from 'final-form';
import {
  createElement,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import { HttpMutationFormResponseTransformContext } from './http-mutation-form-response-transform';

export const REQUEST_CREATOR_ERROR = 'netspend/requestCreatorError';

const isPromise = (x: unknown): x is Promise<any> =>
  !!x && typeof (x as Promise<any>).then === 'function';

type SupportedFinalFormProps = Pick<
  FormProps,
  | 'decorators'
  | 'initialValuesEqual'
  | 'debug'
  | 'destroyOnUnregister'
  | 'initialValues'
  | 'keepDirtyOnReinitialize'
  | 'mutators'
  | 'validateOnBlur'
>;

export interface MutationFetchFormProps extends SupportedFinalFormProps {
  mutationOnSubmit: (values: any) => RequestConfig | Promise<RequestConfig>;
  onSubmitSuccess?: (data: any) => void;
  /**
   * Override the actual form being rendered. Useful for intercepting on the onSubmit,
   * or styling the form differently.
   * @default <form />
   */
  children?:
    | ReactNode
    | ((props: {
        handleSubmit: FormRenderProps['handleSubmit'];
        form: FormRenderProps['form'];
      }) => ReactNode);
}

export const MutationFetchForm: FC<MutationFetchFormProps> = ({
  children,
  mutationOnSubmit,
  onSubmitSuccess,
  debug,
  decorators,
  destroyOnUnregister,
  initialValues,
  initialValuesEqual,
  keepDirtyOnReinitialize,
  mutators,
  validateOnBlur
}) => {
  const httpMutationFormResponseTransformer = useContext(
    HttpMutationFormResponseTransformContext
  );

  const isMountedRef = useRef(false);
  const childrenRef = useRef(children);
  const formCallbackRef = useRef<(errors?: object) => void>();
  const onSubmitSuccessRef = useRef(onSubmitSuccess);
  const httpMutationFormResponseTransformerRef = useRef(
    httpMutationFormResponseTransformer
  );

  // Keep refs updated to the latest values
  onSubmitSuccessRef.current = onSubmitSuccess;
  httpMutationFormResponseTransformerRef.current = httpMutationFormResponseTransformer;

  const [
    ,
    response,
    data,
    request
  ] = useHttpMutationWithErrorsWithLoadingWithLazyRequest();

  // Start the form submission
  const handleFormSubmit = useCallback<FinalFormConfig['onSubmit']>(
    (values: Record<string, Promise<any> | any>, form, callback) => {
      formCallbackRef.current = callback;

      const requestConfigOrAsyncRequestConfig = mutationOnSubmit(values);

      if (isPromise(requestConfigOrAsyncRequestConfig)) {
        requestConfigOrAsyncRequestConfig.then(
          (resolvedRequestConfig) => {
            if (!isMountedRef.current) return;
            request(resolvedRequestConfig);
          },
          (e) => {
            if (!isMountedRef.current) return;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            callback!({
              [FORM_ERROR]: (e && e[FORM_ERROR]) || REQUEST_CREATOR_ERROR
            });
          }
        );
        return;
      }

      request(requestConfigOrAsyncRequestConfig);
    },
    [mutationOnSubmit, request]
  );
  // Emit finished form submission
  useEffect(() => {
    if (response) {
      const result = httpMutationFormResponseTransformerRef.current(
        response,
        data
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formCallbackRef.current!(result);
      // Notify parent of submitted form
      if (!result && onSubmitSuccessRef.current)
        onSubmitSuccessRef.current(data);
    }
  }, [response, data]);

  childrenRef.current = children;
  const renderChildren = useCallback<(props: FormRenderProps) => ReactNode>(
    ({ handleSubmit }) => (
      <form noValidate style={{ display: 'inline' }} onSubmit={handleSubmit}>
        {childrenRef.current}
      </form>
    ),
    []
  );

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <Form
      subscription={{}}
      onSubmit={handleFormSubmit}
      debug={debug}
      decorators={decorators}
      destroyOnUnregister={destroyOnUnregister}
      initialValues={initialValues}
      initialValuesEqual={initialValuesEqual}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      mutators={mutators}
      validateOnBlur={validateOnBlur}
    >
      {typeof children === 'function' ? children : renderChildren}
    </Form>
  );
};
